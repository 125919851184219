// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-duanzi-js": () => import("../src/templates/duanzi.js" /* webpackChunkName: "component---src-templates-duanzi-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-img-js": () => import("../src/templates/img.js" /* webpackChunkName: "component---src-templates-img-js" */),
  "component---src-templates-pic-js": () => import("../src/templates/pic.js" /* webpackChunkName: "component---src-templates-pic-js" */),
  "component---src-templates-fuli-js": () => import("../src/templates/fuli.js" /* webpackChunkName: "component---src-templates-fuli-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

